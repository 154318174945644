import { ReactNode } from 'react'
import clsx from 'clsx'

/* blocks */
import Header from '@/cmp/molecules/Header/Header'
import Seo from '@/molecules/SEO/SEO'
import PromotionalBanner from '@/molecules/PromotionalBanner/PromotionalBanner'
import Footer from '@/cmp/molecules/Footer/Footer'
import {
  platformData,
  solutionsData,
  resourcesData,
  talentNetworkData
} from '@/cmp/molecules/Header/Header.utils'

/* utils */
import strapiData from '@/data/cmp/strapi_data.json'
import { inter, interLocal } from '@/styles/fonts/fonts'

export const runtime = 'edge'

interface Meta {
  property: string
  content: string
}

interface Props {
  children: ReactNode
  title: string
  meta?: Meta[]
  canonical?: string
  primaryCta?: { title: string; url: string }
  secondaryCta?: { title: string; url: string }
  tertiaryCta?: { title: string; url: string }
  jsonSchema?: LDJsonSchema[]
  showNavLinks?: boolean
  noIndex?: boolean
  mode?: 'dark' | 'light'
}

const resources = strapiData.data.resources.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: item.attributes.slug
}))

function Layout({
  title,
  meta,
  children,
  canonical = '',
  jsonSchema,
  primaryCta,
  secondaryCta,
  tertiaryCta,
  showNavLinks = true,
  noIndex = false,
  mode = 'dark'
}: Props) {
  return (
    <div className={clsx(inter.className, interLocal.variable)}>
      <Seo
        title={title}
        meta={meta}
        canonical={canonical}
        jsonSchema={jsonSchema}
        noIndex={noIndex}
      />
      <PromotionalBanner />
      <Header
        showNavLinks={showNavLinks}
        primaryCta={primaryCta}
        secondaryCta={secondaryCta}
        tertiaryCta={tertiaryCta}
        platformData={platformData}
        solutionsData={solutionsData}
        resourcesData={resourcesData}
        talentNetworkData={talentNetworkData}
        mode={mode}
      />
      <main data-test-id="main">{children}</main>

      {showNavLinks && <Footer resources={resources} />}
    </div>
  )
}

export default Layout
